import { merge } from "theme-ui"
import { tailwind } from "@theme-ui/presets"

const theme = merge(tailwind, {
  initialColorModeName: `light`,
  config: {
    useCustomProperties: true,
  },
  main: {
    overflowY: `hidden`,
  },
  fonts: {
    body: 'nort, system-ui, sans-serif',
    heading: 'nort, "Avenir Next", sans-serif',
    monospace: 'nort, Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 900,
    bold: 700,
  },
  colors: {
    primary: '#FF2D1D',
    secondary: '#1C0248',
    text: '#1C0248',
    nav_links: '#1C0248',
    button_yellow: '#1C0248',
    button_yellow_bg: '#FCD901',
    button_yellow_hover: '#FF2D1D',
    button_pink: '#1C0248',
    button_pink_bg: '#FFA4CA',
    button_pink_hover: '#FF2D1D',
    button_blue: '#1C0248',
    button_blue_bg: '#1C0248',
    button_blue_hover: '#FCD901',
    button_toggle: '#FFA4CA',
    button_toggle_bg: '#1C0248',
    button_toggle_hover: '#FCD901',
    hero_heading: '#1C0248',
    heading: '#1C0248',
    background: '#FFA4CA',
    divider: '#ffffff',
    color_divider_bg: '#FCD901',
    color_divider_heading: '#1C0248',
    color_divider_text: '#1C0248',
    wave: '#ffffff',
    textMuted: '#FCD901',
    icon_pink: '#1C0248',
    icon_red: '#FF2D1D',
    icon_blue: '#30B7D5',
    icon_orange: '#FF2D1D',
    icon_yellow: '#FCD901',
    icon_purple: '#30B7D5',
    icon_green: '#FCD901',
    icon_brightest: 'rgba(28, 2, 72, 0.04)',
    icon_darker:  'rgba(28, 2, 72, 0.08)',
    icon_darkest:  'rgba(28, 2, 72, 0.16)',
    modes: {
      dark: {
        primary: '#FCD901',
        secondary: '#1C0248',
        text: '#1C0248',
        nav_links: '#ffffff',
        button_yellow: '#FF2D1D',
        button_yellow_bg: '#FFA4CA',
        button_yellow_hover: '#1C0248',
        button_pink: '#FF2D1D',
        button_pink_bg: '#FFA4CA',
        button_pink_hover: '#1C0248',
        button_blue: '#FCD901',
        button_blue_hover: '#1C0248',
        button_toggle: '#1C0248',
        button_toggle_bg: '#FFA4CA',
        button_toggle_hover: '#FF2D1D',
        hero_heading: '#FFA4CA',
        heading: '#1C0248',
        background: '#1C0248',
        divider: '#1C0248',
        color_divider_bg: '#1C0248',
        color_divider_heading: '#FCD901',
        color_divider_text: '#ffffff',
        wave: '#ffffff',
        textMuted: '#FF2D1D',
        icon_brightest: 'rgba(255, 255, 255, 0.04)',
        icon_darker:  'rgba(255, 255, 255, 0.08)',
        icon_darkest:  'rgba(255, 255, 255, 0.16)',
        icon_red: '#FF2D1D',
        icon_blue: '#30B7D5',
        icon_orange: '#FF2D1D',
        icon_yellow: '#FCD901',
        icon_pink: '#FFA4CA',
        icon_purple: '#30B7D5',
        icon_green: '#FCD901',
      },
    },
  },
  breakpoints: [ '321px',`391px`, `668px`, `900px`, `1200px`, `1600px`],
  footer: {
    fontSize: [0, 0, 1],
    textAlign: `center`,
    color: `textMuted`,
    pt: [4, 5],
    pb: [0, 0],
  },
  links: {
    nav: {
      fontWeight: 700,
      color: 'nav_links',
    }
  },
  styles: {
    root: {
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
      color: `text`,
      backgroundColor: `background`,
      a: {
        color: `primary`,
        textDecoration: `none`,
        transition: `all 0.3s ease-in-out`,
        fontWeight: 700,
        "&:hover": {
          color: `primary`,
          textDecoration: `underline`,
        },
      },
    },
    p: {
      fontSize: [0, 0, 1, 1, 2, 2],
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`,
      a: {
        fontSize: [0, 0, 1, 1, 2, 2],
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
      }
    },
    ul: {
      listStyle: 'inside'
    },
    li: {
      fontSize: [0, 0, 1, 1, 2, 2],
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
    blockquote: {
      marginLeft: 0,
      p: {
        fontSize: [0, 1, 2, 3],
        fontWeight: `normal`,
        color: `heading`,
      },
    },
    h1: {
      fontSize: [4, 5, 5, 6, 7, 8],
      mt: 2,
      mb: 3,
      textShadow: `rgba(255, 255, 255, 0.15) 0px 5px 35px`,
      letterSpacing: `0em`,
      color: `heading`,
      textAlign: `center !important`,
    },
    h2: {
      fontSize: [3, 4, 4, 5, 6, 6],
      mt: 2,
      mb: 2,
      letterSpacing: `0em`,
      color: `heading`,
    },
    h3: {
      fontSize: [2, 3, 3, 4, 5, 5],
      mt: 3,
      color: `heading`,
    },
    h4: {
      fontSize: [1, 2, 2, 3, 4, 4],
      color: `heading`,
    },
    h5: {
      fontSize: [0, 1, 1, 2, 3, 3],
      color: `heading`,
    },
    h6: {
      fontSize: [0, 1],
      mb: 2,
      color: `heading`,
    },
  },
  layout: {
    container: {
      maxWidth: `5xl`,
    },
  },
  buttons: {
    toggle: {
      display: `block`, 
      width: 'fit-content',
      mx: `auto`,
      fontSize: [ 1, 1, 1, 2, 2, 2],
      fontWeight: `semibold`,
      textTransform: 'uppercase',
      borderRadius: '40px',
      color: `button_toggle`,
      border: `none`,
      backgroundColor: `button_toggle_bg`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 4,
      py: 2,
      transition: `all 0.3s ease-in-out`,
      "&:hover": {
        color: `button_toggle_hover`,
        textDecoration: `none`,
      },
    },
    yellow: {
      display: `block`, 
      width: 'fit-content',
      mx: `auto`,
      fontSize: [ 1, 1, 1, 2, 2, 2],
      fontWeight: `semibold`,
      textTransform: 'uppercase',
      borderRadius: '40px',
      color: `button_yellow`,
      border: `none`,
      backgroundColor: `button_yellow_bg`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 4,
      py: 2,
      transition: `all 0.3s ease-in-out`,
      "&:hover": {
        color: `button_yellow_hover`,
        textDecoration: `none`,
      },
    },
    blue: {
      display: `block`, 
      width: 'fit-content',
      mx: `auto`,
      fontSize: [ 1, 1, 1, 2, 2, 2],
      fontWeight: `semibold`,
      textTransform: 'uppercase',
      borderRadius: '40px',
      color: `button_blue`,
      border: `none`,
      backgroundColor: `icon_blue`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 4,
      py: 2,
      transition: `all 0.3s ease-in-out`,
      "&:hover": {
        color: `button_blue_hover`,
        textDecoration: `none`,
      },
    },
    pink: {
      display: `block`, 
      width: 'fit-content',
      mx: `auto`,
      fontSize: [ 1, 1, 1, 2, 2, 2],
      fontWeight: `semibold`,
      textTransform: 'uppercase',
      borderRadius: '40px',
      color: `button_pink`,
      border: `none`,
      backgroundColor: `button_pink_bg`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 4,
      py: 2,
      transition: `all 0.3s ease-in-out`,
      "&:hover": {
        color: `button_pink_hover`,
        textDecoration: `none`,
      },
    },
  },
  texts: {
    bigger: {
      h1: {
        lineHeight: '1em',
      },
      p: {
        fontSize: [ 1, 1, 2, 2, 3, 4],
        marginTop: '1',
        lineHeight: '1.2em',
      },
    },
    smaller: {
        p: {
        fontSize: [ 1, 1, 1, 1, 2, 3],
        marginTop: '1',
        lineHeight: '1em',
        },
          li: {
            fontSize: [0, 0, 0, 0, 0, 0],
            color: `text`,
          },
    },
  },
  p: {
    microCopy: {
        p: {
            fontSize: [0, 0, 0, 0, 1, 1]
        },
    },
  },
  headings: {
    smaller: {
        h1: {
            fontSize: [1, 2, 2, 3, 4, 4],
        },
        h2: {
            fontSize: [1, 2, 2, 3, 4, 4],
        },
    },
  },
})

export default theme
